import './loading3.css';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Loading3 = () => {
    return(
      <div class="loader3"></div>
    )
}
export default Loading3;