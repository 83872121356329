// Import necessary libraries and components
import './home.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './authprovider';
import { useNavigate } from 'react-router-dom';
import Accueil from './accueil';
import AdBar from './pub';
import { useSelector } from 'react-redux';
import { selectloading, selectprofil } from './reducer';
import Loading2 from './loading/loading2';
import LoadingSpinner from './loading/loading';


const Home = () => {
  const auth = useAuth();
  const profil = useSelector(selectprofil);
  const navigate = useNavigate();
  const [localUser, setLocalUser] = useState(null);
  const [topProducts, setTopProducts] = useState([]);
  const [ads, setAds] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [logoVisible, setLogoVisible] = useState(true);
  const loading = useSelector(selectloading);
  
if(loading){
  return <LoadingSpinner/>
}

  return (
    <div className={`home-container ${Loading ? 'loading' : ''}`}>
        <>
          <AdBar back='linear-gradient(325deg, rgba(23, 27, 35, 0.5) 0%, rgba(23, 27, 35, 0.5) 14.286%,rgba(52, 54, 51, 0.5) 14.286%, rgba(52, 54, 51, 0.5) 28.572%,rgba(80, 81, 67, 0.5) 28.572%, rgba(80, 81, 67, 0.5) 42.858%,rgba(109, 108, 84, 0.5) 42.858%, rgba(109, 108, 84, 0.5) 57.144%,rgba(137, 134, 100, 0.5) 57.144%, rgba(137, 134, 100, 0.5) 71.43%,rgba(166, 161, 116, 0.5) 71.43%, rgba(166, 161, 116, 0.5) 85.716%,rgba(194, 188, 132, 0.5) 85.716%, rgba(194, 188, 132, 0.5) 100.002%),linear-gradient(323deg, rgb(9, 106, 7) 0%, rgb(9, 106, 7) 14.286%,rgb(21, 127, 44) 14.286%, rgb(21, 127, 44) 28.572%,rgb(34, 149, 81) 28.572%, rgb(34, 149, 81) 42.858%,rgb(46, 170, 118) 42.858%, rgb(46, 170, 118) 57.144%,rgb(58, 191, 155) 57.144%, rgb(58, 191, 155) 71.43%,rgb(71, 213, 192) 71.43%, rgb(71, 213, 192) 85.716%,rgb(83, 234, 229) 85.716%, rgb(83, 234, 229) 100.002%)'/>
          <Accueil />
        </>
    </div>
  );
};

export default Home;
